.bg-theme-1 {
	background: $blue no-repeat center / cover;
	background-image: url('../img/bg/bg.jpg');
	color: color-contrast($blue);
}

.bg-theme-3 {
	background: $light no-repeat center / contain;
	background-image: url('../img/bg/bg-light.jpg');
	color: color-contrast($light);

	@include media-breakpoint-up(xxl) {
		background-size: auto;
	}
}

.bg-theme-2 {
	background-color: $light;
}

.space-sm {
	@include padding(5rem 0 5.75rem);
}
.space-md {
	@include padding(7.875rem 0);
}