.event {
  border-radius: 1rem;
  background: $white;
  box-shadow: 0.4px 1.5px 4.8px 0px rgba(#093745, 0.04), 
              1.8px 7px 10.1px 0px rgba(#093745, 0.07), 
              4.5px 17.1px 20.1px 0px rgba(#093745, 0.09), 
              8.8px 33.1px 39.4px 0px rgba(#093745, 0.11), 
              14.8px 55.7px 72.6px 0px rgba(#093745, 0.14), 
              22.9px 85.9px 124px 0px rgba(#093745, 0.18);

  position: relative;
  color: $white;


  background: var(--sk-event-bg-image) no-repeat center / cover;

}

.event-bg {
  position: absolute;
  border-radius: 1rem;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url('../img/bg/overlay.png') no-repeat center / cover;
}

.event-inner {
  @include padding(2.5rem);
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(xxl) {
    @include padding(3rem 5.5rem);
  }
}


.event-body {
  @include font-size(2.5rem);
  font-weight: $font-weight-bold;
  letter-spacing: -0.76px;
  padding: .75rem 0;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding: .75rem 1.5rem;
    text-align: left;
  }
}

.event-grow {
  margin-bottom: 1.5rem;
  letter-spacing: -1.52px;
}

.event-overlay {
  border-radius: 1rem;
  background: rgba(#1A5968, .8);
  @include padding(3.4375rem 2.875rem .875rem);    
}

.event-overlay-link {
  @include padding-bottom(2.875rem);
  margin-bottom: -130px;

  .btn-secondary {
    --bs-btn-border-radius: 1rem;

  }
}

.event-attr {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  @include padding(1.5rem 0);
  padding-left: 3rem !important;
  border-top: $border-width solid #527684;
  letter-spacing: -0.608px;
  font-weight: $font-weight-bold;
  @include font-size(2rem);

  &:first-child {
    border-top: 0;
  }

  .fi {
    --#{$prefix-my}fi-size: 2rem;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}


.event-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: $transition-base;
  border-radius: .5rem;
  background-color: #1E3243;
  @include padding(1rem 2.5rem);
  color: #CCDBE5;
  font-weight: $font-weight-black;
  letter-spacing: -0.572px;
  line-height: 1.2;
  @include font-size(1.625rem);

  span {
    display: block;
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }

  &.active {
    color: $white;
    background: $primary;
  }
}

.event-wrap {
  background-color: #C8DCE3;
  position: relative;
  z-index: 1;
  @include padding-bottom(11.25rem);
  .overflow-hidden {
    padding-top: 102px;
    margin-top: -13rem;
  }
}


.event-logo {
  --#{$prefix-my}event-logo-radius: .5rem;
  --#{$prefix-my}event-logo-margin: 0 auto 2.75rem;
  --#{$prefix-my}event-logo-height: 70px;
  --#{$prefix-my}event-logo-padding: .75rem;

  display: block;
  border-radius: var(--#{$prefix-my}event-logo-radius);
  @include margin(var(--#{$prefix-my}event-logo-margin));
  background: $white;
  height: var(--#{$prefix-my}event-logo-height);
  padding: var(--#{$prefix-my}event-logo-padding);

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: scale-down;
  }
}

.event-logo-sm {
  --#{$prefix-my}event-logo-margin: 0 auto;
  --#{$prefix-my}event-logo-height: 42px;
  --#{$prefix-my}event-logo-padding: .375rem;
}

.event-sponsor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  font-size: .75rem;
  font-weight: $font-weight-bold;
  letter-spacing: -0.228px;
}

.event-dt {
  border: $border-width dashed #B1C4CA;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
}

.event-date {
  font-weight: $font-weight-black;
  @include font-size(1.75rem);
  letter-spacing: -0.608px;
  text-transform: uppercase;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .25rem;
  border-right: $border-width dashed #B1C4CA;
  @include padding(1.25rem 0 1.25rem 1.125rem);
  white-space: nowrap;
}

.event-date-col {
  @include font-size(1rem);
  line-height: 1.25;
}

.event-time {
  @include font-size(1.25rem);
  font-weight: $font-weight-bold;
  letter-spacing: -0.38px;
  text-align: center;
  @include padding(1.25rem 0);

  &:not(.event-time-col) {
    div:first-child {
      &:after {
        content: "";
        display: block;
        height: .5rem;
        width: $border-width;
        border: $border-width solid var(--#{$prefix}card-color);
        margin: .125rem auto;
      }
    }
  }
}

.event-time-col {
  text-align: left;
  @include padding(1.25rem .9375rem);
  @include font-size(.9375rem);
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  b {
    display: inline-block;
    width: 24px;
  }
  @include media-breakpoint-down(xl) {
    @include font-size(.75rem);
  }
}

.slider-event {
  .slider-control {
    border-color: $primary;
    background-color: $primary;
    color: $white;
    position: absolute;
    top: 50%;
    width: auto;

    &:hover { 
      background-color: tint-color($primary, 20%);
      border-color: tint-color($primary, 20%);
    }
  }

  .slider-control-prev {
    right: calc(100% + 100px);
  }
  .slider-control-next {
    left: calc(100% + 100px);
  }

  .slick-list {
    @include media-breakpoint-up(md) {
      clip-path: inset(-1rem -50vw 0 0);
    }
  }
}


.card-event2 {
  --#{$prefix}card-border-radius: 1rem;
  --#{$prefix}card-inner-border-radius: 1rem;
  --#{$prefix}card-title-spacer-y: .5rem;
  --#{$prefix}card-bg: #{to-rgb($body-bg)};
  --#{$prefix}card-height: 100%;
  --#{$prefix}card-color: #194B57;
  --#{$prefix}card-title-color: #{var(--#{$prefix}card-color)}; 

  background: linear-gradient(180deg, rgba(var(--#{$prefix}card-bg), 0) 23%, rgba(var(--#{$prefix}card-bg), 1) 43%), var(--sk-event-bg-image) no-repeat center top / auto 45%;
  position: relative;

  .card-body {
    background: rgba(var(--#{$prefix}card-bg), .5);
    display: flex;
    flex-direction: column;
    border-radius: var(--#{$prefix}card-border-radius);
  }

  .card-title {
    font-weight: $font-weight-bold;
    letter-spacing: -0.418px;
    @include font-size(1.375rem);

    height: calc(3*1.2*1.375rem);
  }

  .event-logo:not(.event-logo-sm) {
    --#{$prefix-my}event-logo-margin: 4.625rem auto 2.75rem;
    width: 200px;
  }

  .event-logo-sm {
    width: 106px;
  }

  .location {
    margin-bottom: 1.5rem;
  }
}

.card-event-now {
  --#{$prefix}card-bg: #{to-rgb(#194B57)};
  --#{$prefix}card-color: #{$white};

  &:before {
    content: "Nächstes Festival";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-1rem , 1rem);
    letter-spacing: -0.304px;
    font-weight: $font-weight-black;
    line-height: 1.2;
    color: $white;
    background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='174' height='58' viewBox='0 0 174 58' fill='none'><path transform='rotate(-0.7)' d='M4.73171 15.373C6.00765 7.738 12.5664 2.11141 20.3067 2.01166L157.31 0.246122C167.283 0.117606 174.941 9.04501 173.297 18.8821L169.431 42.0175C168.155 49.6525 161.596 55.2791 153.856 55.3789L16.8527 57.1444C6.88006 57.2729 -0.778539 48.3455 0.865398 38.5084L4.73171 15.373Z' fill='#{$primary}'/></svg>")) no-repeat center / cover;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
  }
}


.card-event-past {
  &:before {
    content: "Vergangenes Festival";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-1rem , 1rem);
    letter-spacing: -0.304px;
    font-weight: $font-weight-black;
    line-height: 1.2;
    color: $black;
    background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='174' height='58' viewBox='0 0 174 58' fill='none'><path transform='rotate(-0.7)' d='M4.73171 15.373C6.00765 7.738 12.5664 2.11141 20.3067 2.01166L157.31 0.246122C167.283 0.117606 174.941 9.04501 173.297 18.8821L169.431 42.0175C168.155 49.6525 161.596 55.2791 153.856 55.3789L16.8527 57.1444C6.88006 57.2729 -0.778539 48.3455 0.865398 38.5084L4.73171 15.373Z' fill='#{$tertiary}'/></svg>")) no-repeat center / cover;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
  }
}

