.form-label {
  letter-spacing: -0.396px;
}

.form {
  
}

.form-check.d-flex {
  .form-check-input {
    flex: 0 0 $form-check-input-width;
  }
}

.form-modal {
  .form-label {
    color: $body-color;
  }
  .form-control,
  .form-select,
  .form-check-input {
    border-color: #67859B;
    background-color: $gray-100;
  }

  .form-check-input:checked {
    border-color: $primary;
    background-color: $primary;
  }
}

.festival-dropdown {
  padding: $input-padding-y $input-padding-x;
}

a[data-bs-toggle="dropdown"] {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

#cForm {
  .form-control {
    color: $white;
    &:disabled,
    &.disabled {
      background-color: rgba(#2d3e3a, .3);
    }
  }
}