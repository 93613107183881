.modal-lg {
  @include media-breakpoint-up(md) {
    @include rfs(3.75rem, --#{$prefix}modal-padding);
  }
}

.modal {
  .btn-close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  .price-hide {
    display: none;
  }

  &[data-sk-price='basic'] {
    .price-basic {
      display: block;
    }
    .ic-check {
      --sk-ic-bg-color: #{$primary};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }
  }


  &[data-sk-price='kmu'] {
    .price-kmu {
      display: block;
    }
  }


  &[data-sk-price='basic'],
  &[data-sk-price='kmu'] {
    .ic-check {
      --sk-ic-bg-color: #{$primary};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }
  }

  &[data-sk-price='plus'] {
    .price-plus {
      display: block;
    }
    .ic-check {
      --sk-ic-bg-color: #{$secondary};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }
  }

  &[data-sk-price='pro'] {
    .price-pro {
      display: block;
    }
    .table-col-price {
      --#{$prefix-my}table-price-bg: #EDC1E0;
    }
  }

  &[data-sk-price='business'] {
    .price-business {
      display: block;
    }
    .ic-check {
      --sk-ic-bg-color: #{$dark};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }

    .table-col-price {
      --#{$prefix-my}table-price-bg: #9CC8D3;
    }
  }

  &[data-sk-price='digital'] {
    .price-digital {
      display: block;
    }
  }
  &[data-sk-price='digitalonly'] {
    .price-digitalonly {
      display: block;
    }
  }

  &[data-sk-price='digitalonly'],
  &[data-sk-price='digital'] {
    .ic-check {
      --sk-ic-bg-color: #{$tertiary};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$body-color}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }
  }
}
