.fi {
  --#{$prefix-my}fi-size: 24px;
  --#{$prefix-my}fi-stroke: 2;

  width: var(--#{$prefix-my}fi-size);
  height: var(--#{$prefix-my}fi-size);
  stroke: currentColor;
  stroke-width: var(--#{$prefix-my}fi-stroke);
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transition: stroke 0.3s ease-in-out;
}
.fi-fill {
  stroke: none;
  fill: currentColor;
}
.fi-12 {
  --#{$prefix-my}fi-size: 12px;
} 
.fi-16 {
  --#{$prefix-my}fi-size: 16px;
}
.fi-20 {
  --#{$prefix-my}fi-size: 20px;
}
.fi-24 {
  --#{$prefix-my}fi-size: 24px;
}
.fi-28 {
  --#{$prefix-my}fi-size: 28px;
}
.fi-32 {
  @include rfs(32px, --#{$prefix-my}fi-size);
}
.fi-36 {
  @include rfs(36px, --#{$prefix-my}fi-size);
}
.fi-40 {
  @include rfs(40px, --#{$prefix-my}fi-size);
}
.fi-48 {
  @include rfs(48px, --#{$prefix-my}fi-size);
}
.fi-60 {
  @include rfs(60px, --#{$prefix-my}fi-size);
}
.fi-84 {
  @include rfs(84px, --#{$prefix-my}fi-size);
}
.fi-128 {
  @include rfs(128px, --#{$prefix-my}fi-size);
}
.fi-160 {
  @include rfs(160px, --#{$prefix-my}fi-size);
}

.ic-email {
  background-color: #074B60;
  border-radius: 50%;
  padding: 2.375rem;
  .fi {
    @include rfs(64px, --#{$prefix-my}fi-size);
  }
}

.ic {
  --#{$prefix-my}ic-size: 20px;
  --#{$prefix-my}ic-bg-size: 12px;
  --#{$prefix-my}ic-bg-color: #{$primary};
  --#{$prefix-my}ic-color: #{$white};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  width: var(--#{$prefix-my}ic-size);
  height: var(--#{$prefix-my}ic-size);
  flex: 0 0 var(--#{$prefix-my}ic-size);
  color: $white;
  text-decoration: none;
  z-index: 2;
  transition: $transition-base;
  position: relative;
  background: var(--#{$prefix-my}ic-bg-color) no-repeat center / var(--#{$prefix-my}ic-bg-size);

}

.ic-md {
  --#{$prefix-my}ic-size: 24px;
  --#{$prefix-my}ic-bg-size: 16px;
}

.ic-check {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
}
.ic-dash {
  --#{$prefix-my}ic-bg-color: transparent;
  --#{$prefix-my}ic-color: #577284;
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#577284' class='bi bi-dash-lg' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z'/></svg>"));
}

.ic-60 {
  @include rfs(60px, --#{$prefix-my}ic-size);
  .fi {
    @include rfs(32px, --#{$prefix-my}fi-size);
  }
}