
.klaro {
  --notice-max-width: 600px;
  .cn-ok {
    padding-top: 0rem!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column !important;
    @include media-breakpoint-up(sm) {
      flex-direction: row!important;
      padding-top: .5rem!important; } }

  h1.title {
    @extend .h3;
    margin-bottom: 1.5rem; } }

.cn-ok {
  .cm-link {
    @extend .btn;
    @extend .btn-link; } }

.cm-btn {
  @extend .btn;
  --bs-btn-padding-x: 1.25rem;

  & + & {
    margin-left: 0rem !important;
    margin-top: 0px !important;
    @include media-breakpoint-up(md) {
      margin-left: .5rem !important;
      margin-top: 0!important; } } }
.cn-buttons {
  .cm-btn {
    & + & {}
    margin-top: 0px !important;
    margin-left: 0rem !important;
    @include media-breakpoint-up(sm) {
      margin-left: .5rem !important; } } }

.cm-purpose {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0; } }

.cm-btn-danger {
  @extend .btn-secondary; }

.cm-btn-success {
  @extend .btn-primary; }

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
  margin-bottom: 1rem; }

.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em 0;
  margin: 0 5%; }

.klaro .cookie-modal .cm-modal.cm-klaro {
  border-radius: 1rem; }

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

