/* Slider */
.slick-slider {

  position: relative;

  display: block;
  box-sizing: border-box;

  user-select: none;

  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {

  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {

  outline: none;
}
.slick-list.dragging {

  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {

  transform: translate3d(0, 0, 0);
}

.slick-track {

  position: relative;
  top: 0;
  left: 0;
  min-width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {

  display: table;

  content: '';
}
.slick-track:after {

  clear: both;
}
.slick-loading .slick-track {

  visibility: hidden;
}

.slick-slide {

  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
  &:first-child {
    display: block;
  }
}
[dir='rtl'] .slick-slide {

  float: right;
}
.slick-slide img {

  display: block;
}
.slick-slide.slick-loading img {

  display: none;
}
.slick-slide.dragging img {

  pointer-events: none;
}
.slick-initialized .slick-slide {

  display: block;
}

.slick-initialized.row {
  .slick-slide {
    @include make-col-ready();
  }
}

.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {

  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden  {
  display: none;
}

.slider {
  .slick-track {
    display: flex;
    justify-content: center;
  }
}

.slider-visible {
  .slick-list {
    overflow: visible;
  }
}

.slider-stretched {

  .slick-track,
  .slick-list {
    height: 100%
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .slick-slide {
    height: auto;
    flex: 0 0 auto;
  }
}

.slider-box {
  .slick-slide {
    opacity: 0;
    transition: $transition-fade;
  }
  .slick-active,
  .slick-op {
    opacity: 1;
  }
}


.slider-dots {
  list-style: none;
  height: 18px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-top: 1.5rem;
}

.slider-dots-item {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $light;
  border: 2px solid $primary;
  cursor: pointer;
  transition: $transition-base;
  &:hover {
    background: shade-color($border-color, 40%);
  }
}

.slick-active {
  .slider-dots-item {
    // background: var(--#{$prefix}primary);
    width: 14px;
    height: 14px;
  }
}

.slider-control {
  position: relative;
  display: block;
  z-index: 5;
  cursor: pointer;
  transition: $transition-base;
  margin: 0;
  padding: $spacer;
  border-radius: .75rem;
  background-color: $white;
  color: inherit;

  &:hover {
    background-color: $secondary;
  }

  .fi {
    display: block;
  }

  @include media-breakpoint-down(md) {
    padding: .75rem;
    .fi {
      --#{$prefix-my}fi-size: 20px;
    }
  }
  
}

.slider-control-wrap {
  @include margin-bottom(5rem);

  h1 {
    letter-spacing: -0.88px;
  }
}

.slick-dotted {
  .slider-control {
    transform: translateY(calc(-50% - 16px));

    &-next {
      transform: translateY(calc(-50% - 16px)) rotate(180deg);   
    }
  }
}

.slider-event-controls {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  @include rfs(2rem, 'gap');
  @include margin-bottom(2.875rem);
  @include media-breakpoint-down(md) {
    padding-left: 2rem;
  }
}