.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  min-width: 1px;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.btn-xl {
  --#{$prefix}btn-border-radius: 1rem;
  --#{$prefix}btn-padding-y: 1.125rem;
  @include rfs(1.5rem, --#{$prefix}btn-font-size);

  letter-spacing: -0.456px;

  .fi {
    --#{$prefix-my}fi-size: 26px;
    --#{$prefix-my}fi-stroke: 3;
  }
}