.list-head {
  border-bottom: $border-width solid $body-color;
  margin: 0;
  padding: 0 $list-group-item-padding-x $list-group-item-padding-y/2;
  font-weight: $font-weight-bold;
}

.list-check-modal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: inherit;
  font-size: map-get($font-sizes, 'xs');

  li {
    display: flex;
    flex-wrap: nowrap;
    gap: .75rem;

    span:not(.ic) {
      margin-top: -2px;
    }
  }
}

.list-group-modal {
  font-size: map-get($font-sizes, 'xs');
  .list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    flex-wrap: nowrap;

    &:nth-child(even) {
      background-color: $gray-100;
    }
  }
}
.card-active {
  .list-head {
    border-color: var(--#{$prefix}card-color);
  }
}

.table-col-price {
  --#{$prefix-my}table-price-bg: #{tint-color($tertiary, 80%)};
  border-radius: 1rem;
  padding: .125rem .5rem;
  background-color: var(--#{$prefix-my}table-price-bg);
  font-weight: $font-weight-black;
  white-space: nowrap;
  display: inline-flex;
}

.list-group-table {

  .ic-check {
    --sk-ic-bg-color: #{$tertiary};
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$body-color}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
  }

  .table-col-secondary {
    .ic-check {
      --sk-ic-bg-color: #{$secondary};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }

    .table-col-price {
      --#{$prefix-my}table-price-bg: #EDC1E0;
    }
  }

  .table-col-primary {
    .ic-check {
      --sk-ic-bg-color: #{$primary};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }

    .table-col-price {
      --#{$prefix-my}table-price-bg: #EDC1E0;
    }
  }

  .table-col-dark {
    .ic-check {
      --sk-ic-bg-color: #{$dark};
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>"));
    }

    .table-col-price {
      --#{$prefix-my}table-price-bg: #9CC8D3;
    }
  }

  font-size: map-get($font-sizes, 'xs');

  .list-group-item {   
    padding-top: 0;
    padding-bottom: 0;
    &:nth-child(even) {
      background-color: $gray-100;
    }

    .col-table,
    .row.h-100 .col {
      padding-top: var(--#{$prefix}list-group-item-padding-y);
      padding-bottom: var(--#{$prefix}list-group-item-padding-y);      
      display: flex;
      align-items: center;
    }
    .row.h-100 .col {
      justify-content: center;
      flex-direction: column;      
    }
  }

  .text-bg-dark {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -1*$border-width;
      left: 0;
      right: 0;
      border-top: $border-width solid $dark;
      z-index: 2;
    }
  }
}

.list-group-table-odd {
  .list-group-item { 
    &:nth-child(odd) {
      background-color: $gray-100;
    }
    &:nth-child(even) {
      background-color: transparent;
    }
    &:first-child {
      background-color: transparent;
    }
  }
}