body {
  .notifyjs-corner {
    margin: 1rem .5rem;
    z-index: 2000;

    .notifyjs-wrapper,
    .notifyjs-container {
      margin: 0.25rem;
    }

    .notifyjs-arrow {
      display: none;
    }

  }

  .notifyjs-bootstrap-base {
    padding: .75rem 1rem;
    text-shadow: none;
    background-color: $primary;
    border: 0;
    border-radius: $border-radius;
    white-space: normal;
    padding-left: 3.5rem;
    background-repeat: no-repeat;
    background-position: 1rem;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    width: 75vw;
    max-width: 250px;
  }

  .notifyjs-bootstrap-success {
    color: $white;
    background-color: $success;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path><polyline points='22 4 12 14.01 9 11.01'></polyline></svg>"));
  }

  .notifyjs-bootstrap-error {
    color: $white;
    background-color: $danger;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon><line x1='12' y1='8' x2='12' y2='12'></line><line x1='12' y1='16' x2='12.01' y2='16'></line></svg>"));
    a {
      color: $white;
    }
  }
}
